import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
font-weight: 900 !important;
outline: 6px solid var(--secondary-text) !important;
font-size: 24px;
background-color: var(--secondary);
padding: 16px 24px 12px 24px !important;
transition: all 0.2s cubic-bezier(.46,1.28,.8,1.26);
font-family: 'Grandstander', cursive; border: none !important;
filter: drop-shadow(6px 6px 0 var(--secondary-text));
text-transform: uppercase;
font-weight: 900;
color: var(--secondary-text) !important;
  :hover {
    cursor: pointer !important;
    background-color: var(--primary) !important;
    padding: 16px 12px 12px 12px !important;
    filter: drop-shadow(12px 12px 0px var(--secondary-text));color: var(--secondary-text) !important;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretched;
  width: 50%;
  @media (min-width: 767px) {
    flex-direction: row;
    justify-content: center;
  align-items: center;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 80vw;height: auto;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  margin: 200px auto;
  @media (min-width: 900px) {
    width: 80vw;
    height: auto;
  }
  @media (min-width: 1000px) {
    width: 80vw;height: auto;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You can now mint your Bear! 🎉`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your Bear 🍯`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `You did it! You minted your Bear 🎉 Go let Twitter know what's up! 💖`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ padding:0, backgroundColor: "var(--primary)" }}>
      <s.Container
        flex={1}
        ai={"center"}
        className="backgroundImg"
      >
        <a href={CONFIG.MARKETPLACE_LINK}>
          <StyledLogo alt={"logo"} style={{width: 100}} src={"/logo32.png"} />
        </a>
        
        <ResponsiveWrapper flex={2} ai={"center"} jc={"center"} style={{marginBottom:300}}>
          
          <s.Container
            jc={"center"}
            ai={"center"}
            style={{
              paddingLeft: 0,
            }}
          >      <div class="textertext">
          <div class="heading-text">
          <span class="letter"><h1>T</h1></span>
          <span class="letter"><h1>E</h1></span>
          <span class="letter"><h1>D</h1></span>
          <span class="letter"><h1>D</h1></span>
          <span class="letter"><h1>Y</h1></span>
          <span class="letter"><h1>B</h1></span>
          <span class="letter"><h1>E</h1></span>
          <span class="letter"><h1>A</h1></span>
          <span class="letter"><h1>R</h1></span>
          <span class="letter"><h1>Z</h1></span>
        </div>
    
      
        
        </div>
        <div class="mint-container">
          <div class="centered-container">
            <s.TextTitle
              style={{
                textAlign: "center",
                fontSize: 40,
                fontWeight: "bolder",
                color: "var(--secondary-text)",
                className: "mint-count"
              }}
            >
              {data.totalSupply} / {CONFIG.MAX_SUPPLY}
            </s.TextTitle>
            <s.TextDescription
              style={{
                textAlign: "center",
                color: "var(--primary-text)",
                className: "short-contract"
              }}
            >
              <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                {truncate(CONFIG.CONTRACT_ADDRESS, 24)}
              </StyledLink>
            </s.TextDescription>
            <span
              style={{
                textAlign: "center",
                className: "marketplace-button"
              }}
            >
              <StyledButton
                style={{
                  margin: "0px",
                  zIndex: "21"
                }}
                onClick={(e) => {
                  window.open(CONFIG.MARKETPLACE_LINK, "_blank");
                }}
              >
                {CONFIG.MARKETPLACE}
              </StyledButton>
            </span></div>
            
            {Number(data.totalSupply) >= CONFIG.MAX_SUPPLY ? (
              <>
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  Sold Out!
                </s.TextTitle>
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                  You can still find {CONFIG.NFT_NAME} on
                </s.TextDescription>
                <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                  {CONFIG.MARKETPLACE}
                </StyledLink>
              </>
            ) : (
              <><div class="centered-container">
                <s.TextTitle
                  style={{ textAlign: "center", color: "var(--accent-text)",lineHeight: "1", fontWeight: "bold",fontSize: "2rem"}}
                >
                 Mint is LIVE <br/>
                 Price: {CONFIG.DISPLAY_COST}{" "}
                  {CONFIG.NETWORK.SYMBOL} <br/>
                  Max Mint: {CONFIG.MAX_MINT}{" "}
                </s.TextTitle>
                </div>
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                <div class="centered-container">
                  <s.Container ai={"center"} jc={"center"} style={{zIndex: "21"}}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--secondary-text)",
                        fontWeight: "bolder",
                        fontSize: "25",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <StyledButton style={{zIndex: "21"}}
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container></div>
                ) : (
                  <><div class="centered-container">
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      <s.TextDescription>
                      {feedback } <br/>
                  Max Cost : {CONFIG.DISPLAY_COST * mintAmount}{" "} + GAS
                </s.TextDescription>
                    </s.TextDescription>
                    <s.Container ai={"center"} jc={"center"} fd={"row"} style={{marginBottom: 20}}>
                      <StyledButton
                        style={{ lineHeight: "1", zIndex: "21"}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          decrementMintAmount();
                        }}
                      >
                        -
                      </StyledButton>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        <h2>{mintAmount}</h2>
                      </s.TextDescription>
                      <StyledButton
                      style={{zIndex: "21"}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          incrementMintAmount();
                        }}
                      >
                        +
                      </StyledButton>
                    </s.Container>
                    <s.Container ai={"center"} jc={"center"} fd={"row"} style={{zIndex: "100"}}>
                      <StyledButton
                      style={{zIndex: "21"}}
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs();
                          getData();
                        }}
                      >
                        {claimingNft ? "Minting... 🍯" : "Mint"}
                      </StyledButton>
                    </s.Container></div>
                  </>
                )}
              </>
            )}</div>
          </s.Container>
        
        </ResponsiveWrapper>
        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>
        </s.Container>
      </s.Container>
          <StyledImg src={"/config/images/floor.png"} style={{userSelect: "none",  margin: "0 auto", zIndex: "1"}}></StyledImg>
    </s.Screen>
  );
}

export default App;
